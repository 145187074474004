<template>
	<v-sheet class="asset" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="4" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.asset_status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="8" class="py-1 my-auto text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title>
										<v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}
									</v-list-item-title>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<!-- <v-btn
						v-if="getPermission('asset:create')"
						depressed
						:disabled="pageLoading"
						tile
						color="blue darken-4"
						class="text-white"
						:to="{ name: 'asset-create' }"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn> -->
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						color="blue darken-4"
						class="text-white"
						v-on:click="exportAssetReport"
					>
						<v-icon left> mdi-database-export </v-icon>
						Export
					</v-btn>
					<v-btn
						v-if="false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>
					<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="advanceSearch = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<!-- <template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template> -->
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title>
										<v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}
									</v-list-item-title>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('asset')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('asset')"
									>
									</v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag
									</v-icon>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Asset #, Name, Company Name, Display Name and Email Address"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="asset-report"
			v-on:reload:content="filterRows"
			delete-note="All transactions of this asset will also be deleted."
		></Table>
		<ExportDialog
			endpoint="asset/export?type=asset&current=1"
			title="Assets"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			endpoint="asset/export?type=asset"
			title="Assets"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></ExportDialog>
		<ImportDialog
			endpoint="asset/import?type=asset"
			title="Assets"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="Reports"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import { map } from "lodash";

export default {
	name: "asset-report-listing",
	title: "Listing Asset Report",
	mixins: [ListingMixin],
	data() {
		return {
			pageTitle: "Reports",
			pageBreadcrumbs: [{ text: "Report", disabled: true }],
			endpoint: "report/asset",
			defaultFilter: {},
		};
	},

	methods: {
		exportAssetReport() {
			ApiService.setHeader();
			let downloadURL = process.env.VUE_APP_API_URL + "report-asset/export";
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			const { query } = this.$route;
			if (query) {
				map(query, (row, index) => {
					if (row) {
						downloadURL.searchParams.append(`${index}`, row);
					}
				});
			}
			downloadURL.searchParams.append("token", token);
			window.open(downloadURL, "_blank");
		},
	},

	computed: {
		...mapGetters(["listingSearch"]),
	},
};
</script>
